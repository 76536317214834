import cards from "../../assets/img/common/cards.png";
const moment = require("moment");

const CopyrightScreen = () => {
  return (
    <>
      <div className="copyright_area">
        <div className="container">
          <div className="row align-items-center">
            <div className="co-lg-6 col-md-6 col-sm-12 col-12">
              <div className="copyright_left">
                <p>Copyright © {moment().format("YYYY")} All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CopyrightScreen;
