import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import HeaderScreen from "./header";
import FooterScreen from "./footer";
import CopyrightScreen from "./copyright";

const LayoutScreen = () => {

    return (
        <>
            <HeaderScreen />
            <Outlet />
            <FooterScreen />
            <CopyrightScreen />
        </>
    );
};

export default LayoutScreen;