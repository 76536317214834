import React from "react";
import logo from "./logo.svg";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HomeScreen from "./screens/home";
import LayoutScreen from "./screens/layout/layout";
import ConatctScreen from "./screens/contact";
/// Style
import "./assets/css/bootstrap.min.css";
import "./assets/css/fontawesome.all.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/popup.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/navber.css";
import "./assets/css/meanmenu.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import TourScreen from "./screens/tour";
import MainSerivceScreen from "./screens/services";
import AboutFreesiaScreen from "./screens/aboutus";
import FaqScreen from "./screens/faq";
import PrivacyScreen from "./screens/privacy";
import NewsScreen from "./screens/news";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LayoutScreen />}>
              <Route index element={<HomeScreen />} />
              <Route path="/services" element={<MainSerivceScreen />} />
              {/* <Route path="/tours" element={<TourScreen />} /> */}
              <Route path="/news" element={<NewsScreen />} />
              <Route
                path="/about/freesia-travels-tours"
                element={<AboutFreesiaScreen />}
              />
              <Route
                path="/about/frequently-asked-questions"
                element={<FaqScreen />}
              />
              <Route path="/about/privacy-policy" element={<PrivacyScreen />} />
              <Route path="/contact" element={<ConatctScreen />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
