import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import BannerScreen from "./common/banner";
import ServiceScreen from "./common/service";
import FeatureScreen from "./common/feature";
import AboutUsScreen from "./common/about";
import PartnerScreen from "./common/partner";
import SubscribleScreen from "./common/subscrible";
import bg from "./../assets/img/banner/bg3.jpg";
import bg2 from "./../assets/img/banner/bg.jpg";
import bg3 from "./../assets/img/banner/bg6.jpg";
import { Carousel } from "antd";

const HomeScreen = () => {
  return (
    <>
      <Carousel autoplay easing="all" dotPosition="bottom">
        <BannerScreen backgroundImage={bg} />
        <BannerScreen backgroundImage={bg2} />
        <BannerScreen backgroundImage={bg3} />
      </Carousel>
      <ServiceScreen />
      <AboutUsScreen />
      {/* <FeatureScreen /> */}
      {/* <PartnerScreen /> */}
      <SubscribleScreen />
    </>
  );
};

export default HomeScreen;
