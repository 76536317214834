import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";
const HeaderScreen = () => {
  return (
    <>
      {/* Header Area */}
      <header
        className="main_header_arae"
        style={{ backgroundColor: "#0d6efd" }}
      >
        {/* Top Bar */}
        <div className="topbar-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <ul className="topbar-list">
                  <li>
                    <a href="#!">
                      <i className="fab fa-facebook" />
                    </a>
                    <a href="#!">
                      <i className="fab fa-twitter-square" />
                    </a>
                    <a href="#!">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="#!">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <span>+234 809 944 0021</span>
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <span>contact@freesiatravels.com</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6">
                <ul className="topbar-others-options">
                  <li>
                    <NavLink to="/" end>
                      Login
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/" end>
                      Sign up
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Navbar Bar */}
        <div className="navbar-area">
          <div className="main-responsive-nav">
            <div className="container">
              <div className="main-responsive-menu">
                <div className="logo">
                  <NavLink className="navbar-brand" to="/" end>
                    <img src={logo} alt={process.env.REACT_APP_APPNAME} />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="main-navbar">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <NavLink className="navbar-brand" to="/" end>
                  <img src={logo} alt={process.env.REACT_APP_APPNAME} />
                </NavLink>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/"
                        end
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/services"
                        end
                      >
                        Our Services
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="#" end>
                        About Freesia
                        <i className="fas fa-angle-down" />
                      </NavLink>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                            to="/about/freesia-travels-tours"
                            end
                          >
                            About freesia
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                            to="/about/frequently-asked-questions"
                            end
                          >
                            FAQ
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                            to="/about/privacy-policy"
                            end
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to="/contact"
                        end
                      >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                  {/*                                     <div className="others-options d-flex align-items-center">
                                        <div className="option-item">
                                            <a href="#" className="search-box">
                                                <i className="bi bi-search" />
                                            </a>
                                        </div>
                                        <div className="option-item">
                                            <a href="become-vendor.html" className="btn  btn_navber">
                                                Become a partner
                                            </a>
                                        </div>
                                    </div> */}
                </div>
              </nav>
            </div>
          </div>
          <div className="others-option-for-responsive">
            <div className="container">
              <div className="dot-menu">
                <div className="inner">
                  <div className="circle circle-one" />
                  <div className="circle circle-two" />
                  <div className="circle circle-three" />
                </div>
              </div>
              <div className="container">
                <div className="option-inner">
                  <div className="others-options d-flex align-items-center">
                    <div className="option-item">
                      <a href="#" className="search-box">
                        <i className="fas fa-search" />
                      </a>
                    </div>
                    <div className="option-item">
                      <a href="contact.html" className="btn  btn_navber">
                        Get free quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderScreen;
