import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Banner2Screen from "./common/banner2";
import bg from "./../assets/img/banner/bg2.jpg";
import ServiceScreen from "./common/service";

const MainSerivceScreen = () => {
  return (
    <>
      <Banner2Screen pageName={"Our Services"} backgroundImage={bg} />

      <section id="theme_search_form_tour">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-5">
              <ServiceScreen />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainSerivceScreen;
