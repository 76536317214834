import sv from "../../assets/img/icon/service-1.png";
import sv1 from "../../assets/img/icon/service-2.png";
import sv2 from "../../assets/img/icon/service-3.png";
import sv3 from "../../assets/img/icon/service-4.png";
import sv4 from "../../assets/img/icon/service-5.png";
import sv5 from "../../assets/img/icon/service-6.png";

const ServiceScreen = () => {
  return (
    <>
      {/* Top Service And Tour Area*/}
      <section id="top_service_Freesia" className="section_padding_top">
        <div className="container">
          {/* Section Heading */}
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section_heading_center">
                <h2>Our Customer-Centric Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="top_service_boxed">
                <img src={sv} alt="icon" />
                <h3>Freesia Corporate</h3>
                <p>
                  A dedicated and innovative platform that allows businesses and
                  corporate organisations to conduct travel and tour services
                  from the comfort of their office without a need for a third
                  party. THe value-driven serives leverage technology and
                  strategic payment channels to deliver exceptional services to
                  our corporate customers instantly.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="top_service_boxed">
                <img src={sv1} alt="icon" />
                <h3>B2B APIs</h3>
                <p>
                  Our disruptive APIs enable technology players to seamlessly
                  integrate with us and commence the sales of tickets on their
                  channels. This is expected to empower the community and
                  deliver more value to the stakeholders in the value networks.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="top_service_boxed">
                <img src={sv2} alt="icon" />
                <h3>Holiday Business</h3>
                <p>
                  We believe colleagues in the organisation should have some
                  time off to re-energise to deliver more results for the
                  business. Our holiday business package
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="top_service_boxed">
                <img src={sv3} alt="icon" />
                <h3>Ticket Booking</h3>
                <p>
                  We offer competitive price offerings, seamless bookings from
                  the comfort of your devices, and instant notification and
                  ticket issuance.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="top_service_boxed">
                <img src={sv4} alt="icon" />
                <h3>24/7 Customer Support</h3>
                <p>
                  We are customer-centric and passionate in delivering
                  exceptional customer service to our partners. We have invested
                  in experience and a value-driven customer service team to
                  deliver world-class service to our esteemed customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ServiceScreen;
