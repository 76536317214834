import { useState } from "react";
import email from "../../assets/img/common/email.png";

const SubscribleScreen = () => {
  const [isSubmitEmail, setIsSubmitEmail] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailSubmission = () => {
    if (!emailValue.trim() || !emailRegex.test(emailValue.trim())) {
      setIsEmailValid(false);
      return;
    }

    setIsSubmitEmail(true);
  };

  const handleBackToSupport = () => {
    setEmailValue("");
    setIsEmailValid(true);
    setIsSubmitEmail(false);
  };

  return (
    <>
      {!isSubmitEmail ? (
        <section id="cta_area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="cta_left">
                  <div className="cta_icon">
                    <img src={email} alt="icon" />
                  </div>
                  <div className="cta_content">
                    <h2 className="mb-3">Want to learn more?</h2>
                    <h5>
                      We’re here to help whether you’re first considering
                      business trips, corporate willingly to leverage our
                      services or already have a travel program.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="cat_form">
                  <form id="cta_form_wrappper">
                    <div className="input-group">
                      <input
                        type="text"
                        className={`form-control ${
                          isEmailValid ? "" : "is-invalid"
                        }`}
                        placeholder="Enter your business email"
                        value={emailValue}
                        onChange={(e) => {
                          setEmailValue(e.target.value);
                          setIsEmailValid(true);
                        }}
                      />
                      <div className="invalid-feedback my-3">
                        {emailValue.trim() === ""
                          ? "Email addresss cannot be empty!"
                          : "Kindly input a valid email address!"}
                      </div>
                      <button
                        className="btn btn_theme btn_md"
                        type="button"
                        onClick={handleEmailSubmission}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id="cta_area">
          <div className="container text-center">
            <div className="row align-items-center">
              <div className="col-lg-12 text-center">
                <h4>
                  Thank you for contacting us! We are passionate about serving.
                  Our sales team will reach out for further engagement.
                </h4>
              </div>
            </div>
            <button
              className="btn btn_theme btn_md my-5"
              type="button"
              onClick={handleBackToSupport}
            >
              Back to support
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default SubscribleScreen;
