import abImg from "../../assets/img/common/about-three.png";
import verified from "../../assets/img/common/verified.png";
import play from "../../assets/img/icon/play.png";

const AboutUsScreen = () => {
  return (
    <>
      {/* About area */}
      <section id="three_about_area" className="section_padding_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="three_about_right">
                <h4 className="three_heading_area">Trusted partner</h4>
                <h2>With our experience we will serve you the best</h2>
                <p>
                  With over 20 years of leveraging technology to scale business.
                  We are committed to delivering world-class service to you!
                  Trust Us!
                </p>

                <div className="about_three_flex mb-5">
                  <div className="about_counter_item">
                    <h2>12K</h2>
                    <p>Local guides</p>
                  </div>
                  <div className="about_counter_item">
                    <h2>750+</h2>
                    <p>Destinations</p>
                  </div>
                  <div className="about_counter_item">
                    <h2>100%</h2>
                    <p>Happiness</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsScreen;
