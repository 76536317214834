import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
const FooterScreen = () => {

    return (
        <>
            {/* Footer  */}
            <footer id="footer_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="footer_heading_area">
                                <h5>Need any help?</h5>
                            </div>
                            <div className="footer_first_area">
                                <div className="footer_inquery_area">
                                    <h5>Call 24/7 for any help</h5>
                                    <h6>
                                        {" "}
                                        <a href="tel:+234 8099440021">+234 809 944 0021</a>
                                    </h6><h6>
                                        {" "}
                                        <a href="tel:+234 8099440021">+234 809 944 0021</a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div className="footer_heading_area">
                                <h5></h5>
                            </div>
                            <div className="footer_first_area">
                                <div className="footer_inquery_area">
                                    <h5>Send us a Mail</h5>
                                    <h6>
                                        {" "}
                                        <a href="mailto:support@freesiatravels.com">
                                            support@freesiatravels.com
                                        </a>
                                    </h6>
                                    <h6>
                                        {" "}
                                        <a href="mailto:support@freesiatravels.com">
                                            fly@freesiatravels.com
                                        </a>
                                    </h6>
                                </div>
                                {/* <div className="footer_inquery_area">
                                    <h5>Follow us on</h5>
                                    <ul className="soical_icon_footer">
                                        <li>
                                            <a href="#!">
                                                <i className="fab fa-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!">
                                                <i className="fab fa-twitter-square" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12">
                            <div className="footer_heading_area">
                                <h5>Company</h5>
                            </div>
                            <div className="footer_link_area">
                                <ul>
                                    <li>
                                        <NavLink
                                            to="/about/freesia-travels-tours"
                                            end
                                        >
                                            About Freesia
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/about/privacy-policy"
                                            end
                                        >
                                            Privacy Policy
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="footer_heading_area">
                                <h5>Support</h5>
                            </div>
                            <div className="footer_link_area">
                                <ul>
                                    <li>
                                        <NavLink
                                            to="/about/frequently-asked-questions"
                                            end
                                        >
                                            Faq
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/contact"
                                            end
                                        >
                                            Contact
                                        </NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="footer_heading_area">
                                <h5>Top cities</h5>
                            </div>
                            <div className="footer_link_area">
                                <ul>
                                    <li>
                                        <NavLink
                                            to="/contact"
                                            end
                                        >
                                            New
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/contact"
                                            end
                                        >
                                            City
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    );
};

export default FooterScreen;