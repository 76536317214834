const BannerScreen = ({ backgroundImage }: { backgroundImage: string }) => {
  return (
    <>
      {/* Banner Area */}
      <section
        id="home_three_banner"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPositionY: "3rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_area_three">
                <h3 className="top_banner_three_text">Welcome to Freesia</h3>
                <h2>Experience the world together</h2>
                <h3 className="bottom_banner_three_text">
                  Find awesome flights, hotel, tour, car and packages
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BannerScreen;
