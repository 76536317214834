import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Banner2Screen from "./common/banner2";
import bg from "./../assets/img/banner/bg3.jpg";
import innovation from "./../assets/img/icon/innovation.png";
import excellence from "./../assets/img/icon/excellence.png";
import integrity from "./../assets/img/icon/integrity.png";
import fairness from "./../assets/img/icon/fairness.png";

const AboutFreesiaScreen = () => {
  return (
    <>
      <Banner2Screen pageName={"About Freesia"} backgroundImage={bg} />
      {/* About Us */}
      <section id="about_us_top" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about_us_left">
                <h5>About us</h5>
                <h2>
                  We are positioned to be the most preferred technology-enabled
                  Travel and Tours Company
                </h2>
                <p className="font-lg">
                  We are a high-quality, technology-driven travel and tour
                  services company with the urge to be the best at all times
                  whilst adding value to all stakeholders.
                </p>

                <a href="tour-search.html" className="btn btn_theme btn_md">
                  Find tours
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Service Area */}
      <section id="about_service_offer" className="section_padding_bottom">
        <div className="container">
          <h4 className="mb-3">Our Core Values</h4>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="about_service_boxed">
                <span>
                  <img src={innovation} alt="img" width={28} height={28} />
                </span>

                <h5>
                  <a href="#!">Innovation</a>
                </h5>
                <p>
                  Experience innovation in travel with unique destinations and
                  cutting-edge curation.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="about_service_boxed">
                <img src={fairness} alt="img" width={28} height={28} />
                <h5>
                  <a href="#!">Fairness</a>
                </h5>
                <p>
                  Committed to fairness, we provide inclusive travel with
                  transparent pricing and ethical practices for community
                  benefits.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="about_service_boxed">
                <img src={integrity} alt="img" width={28} height={28} />
                <h5>
                  <a href="#!">Integrity</a>
                </h5>
                <p>
                  Trust is key. We maintain high integrity, keeping promises
                  from accurate travel info to reliable services, ensuring a
                  confident journey built on trust.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="about_service_boxed">
                <img src={excellence} alt="img" width={28} height={28} />
                <h5>
                  <a href="#!">Excellence</a>
                </h5>
                <p>
                  Experience excellence in every aspect of your journey with our
                  committed team of professionals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutFreesiaScreen;
