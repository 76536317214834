import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import bg from "../../assets/img/banner/bg2.jpg";
import { url } from "inspector";

const Banner2Screen = ({
  pageName,
  backgroundImage,
}: {
  pageName: string;
  backgroundImage: string;
}) => {
  return (
    <>
      {/* Common Banner Area */}
      <section
        id="common_banner"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: "blue",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="common_bannner_text">
                <h2>{pageName}</h2>
                <ul>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                      to="/"
                      end
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <span>
                      <i className="fas fa-circle" />
                    </span>{" "}
                    {pageName}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Banner2Screen;
