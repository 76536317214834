import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Banner2Screen from "./common/banner2";
import bg from "./../assets/img/banner/bg5.jpg";
const NewsScreen = () => {

    return (
        <>
            <Banner2Screen pageName={"News & Gist"} backgroundImage={bg} />
            {/* News Area */}
            <section id="news_main_arae" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="section_heading_center">
                                <h2>Latest travel news</h2>
                            </div>
                        </div>
                    </div>
                    <div className="news_tabs_boxed">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_nav_tab">
                                    <nav className="theme_nav_tab_item">
                                        <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                            <button
                                                className="nav-link active"
                                                id="nav-summer-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-summer"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-summer"
                                                aria-selected="false"
                                            >
                                                Summer tour
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-adventure-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-adventure"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-adventure"
                                                aria-selected="false"
                                            >
                                                Adventure
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-travel-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-travel"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-travel"
                                                aria-selected="false"
                                            >
                                                Travel agency
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-group-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-group"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-group"
                                                aria-selected="true"
                                            >
                                                Group tour
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-guide-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-guide"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-guide"
                                                aria-selected="true"
                                            >
                                                Travel guide
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-family-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-family"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-family"
                                                aria-selected="true"
                                            >
                                                Family tour
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-flights-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-flights"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-flights"
                                                aria-selected="true"
                                            >
                                                Flights
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content" id="nav-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="nav-summer"
                                role="tabpanel"
                                aria-labelledby="nav-summer-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Revolutionising the travel industry, one partnership at
                                                        a time
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jennifer lawrence</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Nostrud occaecat reprehenderit elit pariatur do
                                                        occaecat.
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-2.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Connley jimmy</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-3.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Ea non officia minim cupidatat culpa et reprehenderit
                                                        esse ea
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-3.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Astom martin</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Anim labore dolor mollit esse do labore adipisicing
                                                        fugiat
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Simon donald</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-5.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Ex ad cupidatat aliquip nostrud duis deserunt culpa esse
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-5.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jesica aliston</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-6.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Occaecat nulla anim cillum anim id irure nostrud elit
                                                        excepteur nisi
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-6.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Patricia ramise</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-adventure"
                                role="tabpanel"
                                aria-labelledby="nav-adventure-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Revolutionising the travel industry, one partnership at
                                                        a time
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jennifer lawrence</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Nostrud occaecat reprehenderit elit pariatur do
                                                        occaecat.
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-2.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Connley jimmy</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-3.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Ea non officia minim cupidatat culpa et reprehenderit
                                                        esse ea
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-3.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Astom martin</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Anim labore dolor mollit esse do labore adipisicing
                                                        fugiat
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Simon donald</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-travel"
                                role="tabpanel"
                                aria-labelledby="nav-travel-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Anim labore dolor mollit esse do labore adipisicing
                                                        fugiat
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Simon donald</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-5.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Ex ad cupidatat aliquip nostrud duis deserunt culpa esse
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-5.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jesica aliston</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-6.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Occaecat nulla anim cillum anim id irure nostrud elit
                                                        excepteur nisi
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-6.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Patricia ramise</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-group"
                                role="tabpanel"
                                aria-labelledby="nav-group-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Revolutionising the travel industry, one partnership at
                                                        a time
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jennifer lawrence</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Nostrud occaecat reprehenderit elit pariatur do
                                                        occaecat.
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-2.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Connley jimmy</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-3.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Ea non officia minim cupidatat culpa et reprehenderit
                                                        esse ea
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-3.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Astom martin</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Anim labore dolor mollit esse do labore adipisicing
                                                        fugiat
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Simon donald</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-5.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Ex ad cupidatat aliquip nostrud duis deserunt culpa esse
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-5.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jesica aliston</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-6.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Occaecat nulla anim cillum anim id irure nostrud elit
                                                        excepteur nisi
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-6.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Patricia ramise</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-guide"
                                role="tabpanel"
                                aria-labelledby="nav-guide-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Revolutionising the travel industry, one partnership at
                                                        a time
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jennifer lawrence</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-6.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Occaecat nulla anim cillum anim id irure nostrud elit
                                                        excepteur nisi
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-6.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Patricia ramise</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-family"
                                role="tabpanel"
                                aria-labelledby="nav-family-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Revolutionising the travel industry, one partnership at
                                                        a time
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jennifer lawrence</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-2.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Nostrud occaecat reprehenderit elit pariatur do
                                                        occaecat.
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-2.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Connley jimmy</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-flights"
                                role="tabpanel"
                                aria-labelledby="nav-flights-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-1.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Anim labore dolor mollit esse do labore adipisicing
                                                        fugiat
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-1.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Simon donald</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-5.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Ex ad cupidatat aliquip nostrud duis deserunt culpa esse
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-5.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Jesica aliston</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="news_item_boxed">
                                            <div className="news_item_img">
                                                <a href="news-details.html">
                                                    <img src="assets/img/news/news-6.png" alt="img" />
                                                </a>
                                            </div>
                                            <div className="news_item_content">
                                                <h3>
                                                    <a href="news-details.html">
                                                        Occaecat nulla anim cillum anim id irure nostrud elit
                                                        excepteur nisi
                                                    </a>
                                                </h3>
                                                <p>
                                                    Irure enim eiusmod ipsum do Lorem sit consectetur enim
                                                    consectetur. Nostrud ipsum eiusmod eiusmod culpa anim
                                                    excepteur.
                                                </p>
                                            </div>
                                            <div className="news_author_area">
                                                <div className="news_author_img">
                                                    <img src="assets/img/news/author-6.png" alt="img" />
                                                </div>
                                                <div className="news_author_area_name">
                                                    <h4>Patricia ramise</h4>
                                                    <p>
                                                        <a href="news.html">26 Oct 2021</a>{" "}
                                                        <i className="fas fa-circle" /> 8 min read
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="pagination_area">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">«</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            1
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            2
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            3
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">»</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default NewsScreen;