import { useState } from "react";
import Banner2Screen from "./common/banner2";
import bg from "./../assets/img/banner/bg4.jpg";

const ConatctScreen = () => {
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const handleFormSubmission = () => {
    setIsSubmitForm(true);
  };

  const handleBackToSupport = () => {
    setIsSubmitForm(false);
  };

  return (
    <>
      <Banner2Screen pageName={"Contacts"} backgroundImage={bg} />

      <section id="contact_main_arae" className="section_padding">
        <div className="container">
          <div className="contact_main_form_area">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section_heading_center">
                  <h2>Leave us a message</h2>
                </div>

                <div className="contact_form">
                  {!isSubmitForm ? (
                    <form action="!#" id="contact_form_content">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control bg_input"
                              placeholder="First name*"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control bg_input"
                              placeholder="Last name*"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control bg_input"
                              placeholder="Email address (Optional)"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control bg_input"
                              placeholder="Mobile number*"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              className="form-control bg_input"
                              rows={5}
                              placeholder="Message"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn_theme btn_md"
                              onClick={handleFormSubmission}
                            >
                              Send message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <section id="contact_main_arae" className="section_padding">
                      <div className="text-center">
                        <div className="container text-center">
                          <h4>
                            Thank you for contacting us! We are passionate about
                            serving. Our sales team will reach out for further
                            engagement.
                          </h4>
                        </div>
                        <button
                          className="btn btn_theme btn_md my-5"
                          type="button"
                          onClick={handleBackToSupport}
                        >
                          Contact Form
                        </button>
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConatctScreen;
